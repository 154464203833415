<template>
	<v-list v-cloak two-line subheader rounded class="pa-0">
		<v-list-item
			v-for="subscription in subscriptions"
			:key="`susbscription${subscription.id}`"
			:to="{ name: 'Subscription', params: { id: subscription.id } }"
		>
			<v-list-item-content>
				<v-list-item-title>
					{{ subscription.company.name }}
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ subscription.type.name }}
				</v-list-item-subtitle>
				<span class="text-body-2">
					{{ humanTime(subscription.timestamp.start) }} - {{ subscription.timestamp.end ? humanTime(subscription.timestamp.start) : '' }}
				</span>
			</v-list-item-content>
			<v-spacer />
			<v-list-item-action-text>
				<span v-if="subscription.status.canceled.value" class="text-body-1 red--text">
					{{ $t('offers.canceled') }}
				</span>
				<span v-else-if="subscription.status.active.value" class="text-body-1 green--text">
					{{ $t('offers.active') }}
				</span>
				<span v-else-if="subscription.status.finished.value" class="text-body-1 blue--text">
					{{ $t('offers.finished') }}
				</span>
			</v-list-item-action-text>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: 'SubscriptionList',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		subscriptions: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('DD MMM YYYY')
		}
	}
}
</script>
